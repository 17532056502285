<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar title="Entretiens" subtitle="Liste des entretiens disponibles">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter un entretien'"
        @click="storeEntretien"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="entretien in entretiens" :key="entretien.id">
          <entretiens-item :entretien="entretien"></entretiens-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import EntretiensItem from "@/components/collaborateurs/profil/forms/entretien/EntretiensItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: {
    EntretiensItem,
    BaseToolBar,
    BaseButton,
  },
  data() {
    return {
      newEntretien: { collaborateur_id: 0 },
      feedback: {},
      showActives: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      _storeEntretien: "collaborateurs/createEntretien",
    }),
    storeEntretien: function () {
      this.feedback = {};
      this.loading = true;
      this._storeEntretien(this.newEntretien)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.newEntretien.collaborateur_id = this.id;
  },
  computed: {
    ...mapFields("collaborateurs", ["collaborateur.id"]),
    ...mapMultiRowFields("collaborateurs", ["entretiens"]),
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
};
</script>
<style lang="css"></style>
