<template>
  <container-list-extend title="entretien">
    <template v-slot:title>
      <div class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <h6 class="m-0 ml-1" style="line-height: 1rem">
          <span v-if="entretien.bilan">Bilan à 6 ans </span>
          <span v-if="!entretien.bilan">Entretien </span>
          <span>du {{ $moment(entretien.date_realisation).format("DD/MM/YYYY") }}</span>
        </h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="entretien.date_renouvellement">
            Renouvellement le :
            {{ $moment(entretien.date_renouvellement).format("DD/MM/YYYY") }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        @click="updateEntretien(entretien)"
        @delete="deleteEntretien(entretien)"
        :statut="statut"
        :loading="loading"
        deleteBtn
      >
        <base-form-row row>
          <base-inputDatePicker
            v-model="entretien.date_realisation"
            inputText="Date de réalisation"
          ></base-inputDatePicker>
          <base-inputDatePicker
            v-model="entretien.date_renouvellement"
            inputText="Date de renouvellement (alerte)"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <Checkbox
            id="4"
            class="w-100"
            v-model="entretien.bilan"
            text="Bilan des 6 ans ?"
            :errors="feedback.bilan"
            :iconActive="true"
          ></Checkbox>
        </base-form-row>
        <div class>
          <uploader
            :id="'document_entretien_' + entretien.id"
            url="/upload/document"
            file_system="SIRH"
            :file_field="'collaborateurs_entretiens_' + entretien.id"
            :file_key="$route.params.id"
          ></uploader>
        </div>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import Uploader from "@/components/bases/Uploader.vue";
//import BaseInputArea from "@/components/bases/InputArea.vue";
import Checkbox from "@/components/bases/Checkbox.vue";

export default {
  name: "entretiensItem",
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    Uploader,
    Checkbox,
    //BaseInputArea,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
    };
  },
  props: ["entretien"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
  methods: {
    ...mapActions({
      _updateEntretien: "collaborateurs/updateEntretien",
      _deleteEntretien: "collaborateurs/deleteEntretien",
    }),
    updateEntretien: function (entretien) {
      this.feedback = {};
      this.loading = true;
      this._updateEntretien(entretien)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteEntretien: function (entretien) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteEntretien(entretien)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    lib(id, data) {
      const index = data.findIndex((x) => x.id === id);
      return data[index].description;
    },
  },
};
</script>
<style lang="css"></style>
